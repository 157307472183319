.footer {
  background-color: var(--light-gray);
  padding: 30px 0;
}

.footer_link {
  min-width: 250px;
  text-align: center;
}
.footer_link--left {
  min-width: 250px;
  text-align: right;
}
.footer_link--right {
  min-width: 250px;
  text-align: left;
}

.footer_copyright {
  font-size: 14px;
}