*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

a:focus {
  box-shadow: none !important;
}

html {
  --primary-font-color: #1a202c;
  --link-color: #3784ec;
  --dark-orange: #da8300;
  --orange: #f39200;
  --light-orange: #fbdeb3;
  --light-gray: #efefef;
  --lighter-gray: #f7f7f7;
  --dark-gray: #a8a8a8;
  --gray: #c5c5c5;
  --red: #e84e1b;
  --green: #219653;
  --blue: #2f80ed;
}

body {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.main {
  margin: 0 !important;
}

.searchEngine_wrapper,
.page_header {
  background-color: var(--light-gray);
  width: 100%;
  padding: 25px 50px;
}

.searchResults_wrapper {
  width: 100%;
  padding: 25px 50px;
}

@media (max-width: 1000px) {
  .searchEngine_wrapper {
    padding: 25px 10px;
  }
  .searchResults_wrapper {
    padding: 25px 10px;
  }
}

.page_title {
  font-size: 40px;
  font-weight: 300;
}
div.ordersHistory_content {
  width: 70%;
  align-items: flex-start;
  max-width: 1200px;
}
div.account_content {
  width: 70%;
  max-width: 800px;
  min-width: 400px;
  align-items: flex-start;
  padding-bottom: 60px;
}

div.form {
  width: 100%;
  padding: 10px 0;
  align-items: flex-start;
}
div.form_row {
  width: 100%;
  align-items: flex-start;
}

div.accountInfos_section {
  width: 100%;
  align-items: flex-start;
}

.accountInfos_title {
  width: 100%;
  color: var(--orange);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 10px 30px;
  text-align: center;
}

hr.accountInfos_divider {
  border-color: var(--orange);
}

div.accountInfos_checkboxs {
  align-items: flex-start;
  width: 100%;
  padding: 25px 0;
}

.accountInfos_checkboxs-text {
  font-size: 14px;
}

div.accountInfos_buttonWrapper {
  padding-top: 20px;
}

.devextreme_wrapper *,
.devextreme_wrapper *::before,
.devextreme_wrapper *::after {
  font-family: unset;
}

.dx-overlay-wrapper *,
.dx-overlay-wrapper *::before,
.dx-overlay-wrapper *::after {
  font-family: unset;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 10px;
  border: 2px solid #f2f2f2;
}

::-webkit-scrollbar-button {
  display:none;
}