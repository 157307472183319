a.navItem {
  text-align: center;
  padding: 12px 8px;
  min-width: 100px;
  text-decoration: none;
  border-bottom: 6px solid transparent;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button:hover a.navItem,
button:focus a.navItem,
button:active a.navItem,
a.navItem:hover,
a.navItem:focus,
a.navItem:active,
a.navItem--active {
  text-decoration: none;
  border-bottom: 6px solid var(--orange);
}

.navItem_text {
  font-size: 14px;
  font-weight: 600;
}

svg.navItem_icon {
  fill: var(--orange);
  width: 28px;
  height: 28px;
}

.dropdownItem {
  padding: 4px 30px;
  width: 300px;
}

.dropdownItem {
  padding: 4px 30px;
  width: 300px;
}
.dropdownItem_text {
  font-weight: 500;
}

.badge_wrapper {
  position: relative;
}
.badge_text {
  font-size: 10px;
  font-weight: bold;
}

.badge {
  position: absolute;
  display: flex;
  top: -6px;
  background-color: var(--orange);
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  left: 54%;
  transform: translateX(-50%);
  width: 16px;
  height: 16px;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.changeSector {
  -ms-transform: rotate(90deg); /* IE 9 */
  transform: rotate(90deg);
}

span.chakra-switch__track[data-checked] {
  background-color: var(--orange);
}

span.chakra-switch__track {
  background-color: var(--orange);
}
