div.onetimepayment {
  width: 100%;
  padding: 20px 0;
}

.onetimepayment_text {
  padding: 10px;
  font-weight: 400;
  width: 100%;
}

div.onetimepayment_wrapper {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

div.onetimepayment_tabs {
  width: 100%;
}
