div.cardPaymentMethod {
  background-color: var(--light-gray);
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  padding-left: 30px;
  cursor: pointer;
  border-width: 2px;
  border-color: transparent;
}
div.cardPaymentMethod.selected {
  border-color: var(--orange);
}

svg.cardPaymentMethod_icon {
  width: 40px;
  height: 40px;
}

.cardPaymentMethod_text {
  font-size: 18px;
  letter-spacing: 2px;
  min-width: 185px;
}

.cardPaymentMethod_text--newCard {
  font-size: 16px;
}
.cardPaymentMethod_image {
  width: 80px;
  border-radius: 4px;
}

div.otherCard {
  width: 100%;
  height: 135px;
}

div.otherCard_wrapper {
  background-color: var(--light-gray);
  width: 100%;
  height: 70px;
  border-radius: 8px;
  padding: 0px 10px;
  padding-bottom: 30px;
  cursor: pointer;
  border-width: 2px;
  border-color: transparent;
  justify-content: flex-start;
  transition: all 0.3s ease;
}
div.otherCard_wrapper.visible {
  height: 135px;
}

div.otherCard_formWrapper {
  width: 100%;
  max-width: 500px;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0.2s ease;
}

div.otherCard_form {
  height: 200px;
  width: 100%;
  flex: 0;
}

div.otherCard_formWrapper.visible {
  width: 100%;
  max-width: 500px;
  align-items: flex-start;
  opacity: 1;
  visibility: visible;
}

div.otherCard_label {
  width: 100%;
  align-self: flex-start;
  padding-top: 15px;
  padding-left: 30px;
}

div.paymentMethods {
  width: 100%;
  align-items: flex-start;
}
div.paymentMethods_list {
  width: 100%;
  align-items: flex-start;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
}

div.paymentMethod_buttonWrapper {
  width: 100%;
  padding: 10px 0px;
}

button.paymentMethod_button {
  width: 100%;
}

svg.cardPaymentMethod_checkIcon {
  fill: var(--orange);
  width: 35px;
  height: 35px;
  margin-right: 10px;
  opacity: 0;
}
svg.cardPaymentMethod_checkIcon.visible {
  opacity: 1;
}

svg.otherCard_arrowIcon {
  width: 28px;
  height: 28px;
  transition: all 0.3s ease;
}

svg.otherCard_arrowIcon.down {
  transform: rotate(180deg);
}
