.customCheckbox {
  border-color: var(--dark-gray);
}
.customCheckbox span.chakra-checkbox__control {
  border-radius: 50px;
  padding: 4px;
}

.customCheckbox span.chakra-checkbox__control[data-checked] {
  border-color: var(--orange);
  background-color: var(--orange);
}

.customCheckbox svg {
  fill: var(--orange);
}
