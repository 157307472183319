.errorPage_subTitle {
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 2px;
  color: var(--orange);
}
div.errorPage_paragraph {
  width: 100%;
  align-items: flex-start;
}
.errorPage_text {
  font-weight: 300;
}

div.errorPage_content {
  width: 70%;
  min-width: 400px;
  align-items: flex-start;
  flex: 1;
}

.errorPage_text--strong {
  color: var(--orange);
  font-weight: bold;
}

.errorPage_button_wrapper {
  padding-top: 15px;
}

.errorPage_header {
  background-color: var(--light-gray);
  width: 100%;
  padding: 25px 50px;
  justify-content: center;
}

.errorPage_title {
  font-size: 40px;
  font-weight: 300;
}

.errorPage_wrapper {
  width: 800px;
  margin: 40px auto;
  box-shadow: 0px 0px 4px var(--dark-gray);
  border-radius: 4px;
  flex: 1;
}
