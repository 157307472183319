.ibanElement_wrapper {
  background-color: #e5e5e5;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px;
  border-radius: var(--chakra-radii-md);
  margin-bottom: 10px;
}

button.ibanForm_button {
  width: 100%;
}

.ibanForm_content {
  width: 100%;
}

.mandate-acceptance {
  font-size: 14px;
  color: rgba(0, 0, 0, 80%);
  text-align: justify;
}
