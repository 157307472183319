.landingTitle {
  justify-content: center;
  align-items: center;
  flex: 1;
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../assets/images/home_background.jpeg");
  background-size: cover;
  position: relative;
  min-height: calc(100vh - 94px);
}

.landingContent {
  width: 600px;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 20px 15px;
  border-radius: 4px;
}

.landingContent_title {
  color: var(--orange);
  font-size: 28px;
}
.landingContent_text {
  color: black;
}

button.landingContent_button {
  width: 140px;
}

.heading_primary {
  color: var(--orange);
  font-size: 35px;
  font-weight: 250;
  letter-spacing: 2px;
}
.postSources {
  padding: 60px 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 9;
}
.postSources_content {
  width: 80%;
  font-size: 20px;
  font-weight: 300;
}
.postSources_list {
  align-self: start;
  padding-left: 40px;
}

.postSources_image {
  width: 40%;
}

.features {
  padding: 60px 0;
  background-color: var(--light-gray);
}

.feature {
  background-color: white;
  padding: 40px;
  border-radius: 4px;
}

.feature_icon {
  font-size: 50px;
  fill: #e84e1b;
}
hr.feature_divider {
  border-width: 2px;
  border-color: var(--orange);
  height: 90%;
}

div.feature_content {
  align-items: flex-start;
  width: 720px;
}

.feature_title {
  color: var(--orange);
  font-weight: bold;
  font-size: 24px;
}

.feature_paragraph {
  font-size: 20px;
  font-weight: 300;
}
