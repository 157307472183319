.step_buttons {
  padding-top: 15px;
}

div.payment_content {
  width: 90%;
  max-width: 1000px;
  min-width: 400px;
  align-items: flex-start;
}

.orderStep_header {
  background-color: var(--light-gray);
  width: 100%;
  padding: 25px 50px;
  justify-content: center;
}

.orderStep_title {
  font-size: 40px;
  font-weight: 300;
}

.orderStep_wrapper {
  width: 800px;
  margin: auto;
  height: 90%;
  box-shadow: 0px 0px 4px var(--dark-gray);
  border-radius: 4px;
}

.orderStep_wrapper--step3 {
  max-width: 1200px;
  width: 90%;
  margin: 40px auto;
  box-shadow: 0px 0px 4px var(--dark-gray);
  border-radius: 4px;
  flex: 1;
  padding-bottom: 40px;
}
