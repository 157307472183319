.easyProjectsLanding {
  justify-content: center;
  align-items: center;
  flex: 1;
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.15)
    ),
    url("../../assets/images/easyProjectsHome_background.png");
  background-size: cover;
  position: relative;
  min-height: calc(100vh - 94px);
}

.easyProjectsSources {
  padding-top: 60px;
  padding-bottom: 80px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 9;
}
.easyProjectsSources_content {
  width: 80%;
  font-size: 20px;
}
.easyProjectsSources_list {
  align-self: start;
  padding-left: 40px;
}

.easyProjectsFeatures {
  padding: 60px 0;
  background-color: var(--light-gray);
}

.easyProjectsFeatures_description {
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  width: 90%;
  max-width: 1000px;
  text-align: center;
}

.easyProjectsFeature {
  background-color: white;
  padding: 40px;
  border-radius: 4px;
  width: 90%;
  max-width: 1000px;
}

.easyProjectsFeature_icon {
  font-size: 50px;
  fill: #e84e1b;
}
hr.easyProjectsFeature_divider {
  border-width: 2px;
  border-color: var(--orange);
  height: 90%;
}

div.easyProjectsFeature_content {
  align-items: flex-start;
  /* width: 720px; */
}

.easyProjectsFeature_title {
  color: var(--orange);
  font-weight: bold;
  font-size: 28px;
}
.easyProjectsFeature_forWhoParagraph,
.easyProjectsFeature_paragraph {
  font-size: 20px;
  line-height: 24px;
}

.easyProjectsFeature_paragraph {
  font-size: 18px;
}

div.easyProjectsFeature_description {
  align-items: flex-start;
}

div.easyProjectsFeature_forWho {
  justify-content: flex-start;
}

.easyProjectsFeature_forWhoTitle {
  color: var(--orange);
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
}

div.easyProjectsFeature_forWhoContent {
  align-items: flex-start;
}

.easyProjects_headingPrimary {
  color: var(--orange);
  font-size: 40px;
  font-weight: 300;
  /* letter-spacing: 2px; */
}
