div.subscription_paymentForm {
  height: 200px;
}
div.subscription_wrapper {
  width: 100%;
  padding: 20px 25px;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
  min-height: 570px;
  background-image: linear-gradient(103deg, var(--orange) 42%, transparent 42%);
}
div.subscription_wrapper--withPaymentMethods {
  width: 100%;
  padding: 20px 25px;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
  min-height: 570px;
  background-image: linear-gradient(103deg, var(--orange) 42%, transparent 42%);
}
.subscription_title {
  font-size: 24px;
  color: var(--orange);
  font-weight: bold;
}
.subscription_title--withPaymentMethods {
  font-size: 24px;
  color: white;
  font-weight: bold;
}
.subscription_price {
  color: var(--orange);
  font-size: 20px;
  font-weight: 300;
}
.subscription_price--withPaymentMethods {
  color: white;
  font-size: 20px;
  font-weight: 300;
}
.subscription_subTitle {
  font-size: 20px;
  font-weight: 300;
}
div.subscription_priceInfos {
  width: 100%;
  min-width: 140px;
  justify-content: center;
  padding: 0 10px;
}
div.subscription_priceInfosLabels {
  align-items: flex-start;
  width: 30%;
  min-width: 95px;
}
div.subscription_priceInfosLabels p:first-child {
  font-weight: bold;
}
div.subscription_priceInfosLabels p {
  font-weight: 300;
}
div.subscription_priceInfosValues > p:first-child {
  font-weight: bold;
}
div.subscription_priceInfosValues p {
  font-weight: 300;
}
div.subscription_paymentMethods {
  width: 100%;
}
.subscription_formWrapper {
  align-items: flex-start;
}
.subscription_tabPanels {
  height: 100%;
}
div.subscription_tabPanel {
  height: 100%;
}
div.subscription_formWrapper {
  width: 100%;
  height: 100%;
  align-items: center;
}
.subscription_text {
  padding: 10px;
  font-weight: 400;
}
div.subscription_buttonWrapper {
  width: 100%;
}
button.subscription_button {
  width: 100%;
}
.subscription_rightSide {
  width: 100%;
  height: 80%;
}

div.subscription_ibanWrapper {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

div.subscription_tabs {
  height: 100%;
  width: 90%;
}

div.subscription_tabsWrapper {
  height: 100%;
}

div.subscriptionRemiseCode_wrapper {
  width: 100%;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
}

.subscriptionRemiseCode_input {
  background-color: white;
}

.subscriptionRemiseCode_label {
  font-size: 18px;
  font-weight: 700;
  color: white;
}

div.subscriptionRemiseCode_formWrapper {
  width: 100%;
}
